import SwitcherButton from 'Components/js/registration/form-switcher.button'

import CustomerForm from 'Components/js/registration/form'
import PartnerForm from 'Components/js/registration/form-partner'

export default {
    components: {
        SwitcherButton,
        CustomerForm,
        PartnerForm
    },
    props: {
        tabs: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        selected: {}
    }),
    methods: {
        selectTab (tab) {
            this.selected = tab
        }
    },
    mounted () {
        this.selected = this.tabs[0]
    },
    template: `
        <div class="form-swithcer">
            <div class="form-switcher__buttons">
                <switcher-button
                    v-for="tab of tabs"
                    :key="tab.alias"
                    class="form-switcher__button"
                    :selected="selected.alias === tab.alias"
                    @click.native="selectTab(tab)"
                >
                    {{ tab.text }}
                </switcher-button>
            </div>
            <div class="form-switcher__tabs">
                <keep-alive>
                    <component
                        :is="selected.component"
                        v-bind="$attrs"
                        v-on="$listeners"
                        class="form-switcher__tab"
                    />
                </keep-alive>
            </div>
        </div>
    `
}
