import Vue from 'vue'

import FormSwitcher from 'Components/js/registration/form-switcher'
import CustomerForm from 'Components/js/registration/form'

window.RegistrationForm = Vue.component('registration-form', {
    props: {
        partner: {
            type: Boolean,
            default: false
        }
    },
    components: {
        FormSwitcher,
        CustomerForm
    },
    data: () => ({
        tabs: [
            { alias: 'customer', component: 'CustomerForm', text: 'Начать зарабатывать' },
            { alias: 'partner', component: 'PartnerForm', text: 'Стать партнером' },
        ]
    }),
    methods: {
        messageHandler (event) {
            const {
                data: { redirectTo } = {}
            } = event

            if (redirectTo) {
                window.open(redirectTo)

                return undefined
            }
        }
    },
    mounted () {
        window.addEventListener('message', this.messageHandler)
    },
    beforeDestroy () {
        window.removeEventListener('message', this.messageHandler)
    },
    template: `
        <div class="registrtion-form">
            <h2 class="registrtion-form__title">
                Регистрация
            </h2>
            <form-switcher
                v-if="partner"
                v-bind="$attrs"
                v-on="$listeners"
                class="registrtion-form__switcher"
                :tabs="tabs"
            />
            <customer-form
                v-else
                v-bind="$attrs"
                v-on="$listeners"
            />
        </div>
    `
})