import CheckIcon from '!!svg-inline-loader!Components/img/check.svg'

export default {
    name: 'success',
    data () {
        return {
            icons: {
                check: CheckIcon
            }
        }
    },
    template: `
        <div class="registration__step registration__step--full-height">
            <div class="registration__success">
                <div
                    class="registration__success-icon"
                    v-html="icons.check"
                />
                <h3 class="registration__success-title">
                    Номер телефона успешно подтвержден
                </h3>
                <span class="registration__success-description">
                    Подождите, перенаправляем вас в личный кабинет...
                </span>
            </div>
        </div>
    `
}