import axios from 'axios'

import { ValidateMixin, ValidateFieldDirective } from 'Components/js/utils/validation'
import { WButton, WInput } from 'Utils/ui'

export default {
    name: 'phone-change',
    props: {
        phone: String,
        allowCredentialChanging: {
            type: Boolean,
            default: true
        }
    },
    mixins: [ ValidateMixin ],
    directives: {
        'validate-field': ValidateFieldDirective
    },
    components: {
        WButton,
        WInput
    },
    data () {
        return {
            loading: false,
            rules: {
                $id: 'phone_change',
                type: 'object',
                required: [ 'Phone' ],
                properties: {
                    Phone: { type: 'string', pattern: '^79[0-9]{9}$' }
                },
                errorMessage: {
                    properties: {
                        Phone: 'Укажите корректный номер телефона'
                    }
                }
            },
            form: {
                Phone: ''
            }
        }
    },
    methods: {
        getPhoneNumber (val) {
            return `7${val.replace(/\D/g, '')}`
        },
        async onSave () {
            if (this.loading) return undefined

            const data = { Phone: this.getPhoneNumber(this.form.Phone) }

            try {
                this.loading = true

                await this.validation.validate(this.rules, data)
                await axios({
                    url: Url.route('users.phone.confirmation.send'),
                    headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    method: 'POST',
                    data
                })

                this.$emit('on-phone-changed', data.Phone)
            } catch (error) {
                const { data } = error.response || {}

                if (data && data.errors) {
                    Object.keys(data.errors).forEach((key) => {
                        this.validation.setErrors(key, data.errors[key])
                    })
                }
            } finally {
                this.loading = false
            }
        },
        onCancel () {
            this.$emit('on-cancel')
        }
    },
    template: `
        <div class="registration__step registration__step--full-height">
            <div class="registration__item registration__item--pb-lg">
                <w-input
                    v-model="form.Phone"
                    v-validate-field.lazy.eager="{
                        rules: rules,
                        transform: getPhoneNumber
                    }"
                    name="Phone"
                    text="Телефон"
                    placeholder="+7 999 123 45 67"
                    :errors="validation.getErrors('Phone')"
                    :mask="{
                        input: '+7 999 999 99 99',
                        placeholder: '',
                        unmasked: true
                    }"
                />
            </div>
            <div class="registration__item registration__item--pb-sm">
                <w-button
                    wide
                    :loading="loading"
                    @click="onSave()"
                >
                    Сохранить
                </w-button>
            </div>
            <div class="registration__item registration__item--pb-lg">
                <w-button
                    wide
                    outline
                    :disabled="loading"
                    @click="onCancel()"
                >
                    Отменить
                </w-button>
            </div>
            <div
                v-if="allowCredentialChanging"
                class="registration__item registration__item--bottom registration__item--last"
            >
                <span
                    class="registration__link"
                    @click="$emit('on-change-credentials')"
                >
                    Зарегистрироваться через e-mail
                </span>
            </div>
        </div>
    `
}