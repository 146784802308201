import axios from 'axios'
import Vue from 'vue'

import { ValidateFieldDirective, ValidateMixin } from 'Components/js/utils/validation'
import { WButton, WCheckbox, WInput, WRecaptcha } from 'Utils/ui'

import 'Assets/scss/registration.scss'

Vue.prototype.Url = window.Url

const REGEXP_PHONE = new RegExp(/^(\+7|7|8)?[\s\-]?\(?[9][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/)
const REGEXP_EMAIL = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

const getYMClientId = () => {
    return new Promise((resolve, reject) => {
        if (window.ym === undefined) {
            resolve(null)
        }

        try {
            ym(17882743, 'getClientID', function (clientID) {
                resolve(clientID)
            })
        } catch (err) {
            resolve(null)
        }
    })
}

window.LoginForm = Vue.component('login-form', {
    props: {
        isEmbedded: {
            type: Boolean,
            default: false
        }
    },
    mixins: [ ValidateMixin ],
    directives: {
        'validate-field': ValidateFieldDirective
    },
    components: {
        WButton,
        WCheckbox,
        WInput,
        WRecaptcha
    },
    computed: {
        loginDTO () {
            const data = {}
            const required = [
                'Password',
                'Persist',
                'Captcha',
                'SuppressCaptcha'
            ]

            required.forEach((field) => {
                // Copy only filled fields
                if (!this.form[field] && this.form[field] !== false) return undefined
                if (field === 'Captcha' && this.form.SuppressCaptcha) return undefined

                data[field] = this.form[field]
            })

            // Get email/phone
            data[this.identifier.type] = this.identifier.value

            return data
        },
        identifier () {
            const raw = this.form.Login.trim()
            const login = {
                text: 'Телефон или email',
                type: 'Unknown',
                value: raw
            }

            if (REGEXP_PHONE.test(raw)) {
                let phonenumber = raw.replace(/\D/g, '')

                login.text = 'Телефон'
                login.type = 'Phone'
                login.value = `7${phonenumber.slice(-10)}`

                return login
            }

            if (REGEXP_EMAIL.test(raw)) {
                login.text = 'Email'
                login.type = 'Email'
            }

            return login
        },
        getLoginErrors () {
            return this.validation.getErrors('Email').length
                ? this.validation.getErrors('Email')
                : this.validation.getErrors('Phone')
        }
    },
    data () {
        return {
            loading: false,
            doNotRemember: false,
            rules: {
                $id: 'login',
                type: 'object',
                required: [ 'Password', 'Persist' ],
                properties: {
                    Email: { type: 'string', format: 'email' },
                    Phone: { type: 'string', pattern: '^79[0-9]{9}$' },
                    Password: { type: 'string' },
                    Persist: { type: 'boolean' },
                    Captcha: { type: 'string', minLength: 1 },
                    SuppressCaptcha: { type: 'boolean' }
                },
                anyOf: [
                    { required: [ 'Email' ] },
                    { required: [ 'Phone' ] }
                ],
                if: {
                    properties: {
                        SuppressCaptcha: { const: false }
                    }
                },
                then: {
                    required: [ 'Captcha' ]
                },
                errorMessage: {
                    properties: {
                        Email: 'Введите e-mail или телефон в формате +7хххххххххх или 8хххххххххх',
                        Phone: 'Введите e-mail или телефон в формате +7хххххххххх или 8хххххххххх',
                        Password: 'Введите пароль',
                        Captcha: 'Неверная капча'
                    }
                }
            },
            social: [
                'vkontakte',
                // 'facebook',
                // 'twitter',
                'odnoklassniki',
                'mailru'
            ],
            form: {
                Login: '',
                Password: '',
                Persist: true,
                Captcha: '',
                SuppressCaptcha: true
            }
        }
    },
    methods: {
        getFormattedLogin (val) {
            const login = val.trim()

            return REGEXP_PHONE.test(login) ? `7${login.replace(/\D/g, '').slice(-10)}` : login
        },
        clearLoginErrors () {
            this.validation.setErrors('Email', [])
            this.validation.setErrors('Phone', [])
        },
        redirectTo (alias) {
            window.location.href = this.Url.route(alias)
        },
        async checkIfCaptchaRequired (data) {
            if (!data) return undefined

            this.loading = true

            try {
                const login = this.getFormattedLogin(data)
                const response = await axios({
                    url: this.Url.route('users.login.check-captcha-required', { UserName: login }),
                    method: 'GET',
                    headers: { 'X-Requested-With': 'XMLHttpRequest' }
                })

                // We can't be sure what will be passed to response.data
                this.form.SuppressCaptcha = !(response.data === true)
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        async login () {
            if (this.loading) return undefined

            const loginDTO = this.loginDTO
            const data = new FormData()

            for (let [k, v] of Object.entries(loginDTO)) {
                if (k === 'Captcha') {
                    // Remap old captcha to google recaptcha
                    data.set('g-recaptcha-response', v)
                } else {
                    data.set(k, v)
                }
            }

            try {
                const YMClientId = await getYMClientId()

                data.set('YandexMetrikaClientId', YMClientId)

                this.loading = true

                await this.validation.validate(this.rules, loginDTO)
                const response = await axios({
                    url: this.Url.route('users.login'),
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'content-type': 'multipart/form-data'
                    },
                    method: 'POST',
                    data
                })

                const redirect = response.data && response.data.redirect
                    ? response.data.redirect
                    : this.Url.route('users.start')

                this.onLoginSuccess(redirect)
            } catch (error) {
                console.error(error)

                const { status, data } = error.response || {}

                this.loading = false

                if (
                    status === Workle.http.statusCodes.badRequest
                    && this.form.SuppressCaptcha
                    && data.errors.Code
                ) {
                    this.form.SuppressCaptcha = false
                }

                if (status === Workle.http.statusCodes.badRequest && !this.form.SuppressCaptcha) {
                    this.$refs.recaptcha.reset()
                }

                if (data && data.errors) {
                    const loginErrors = [ 'unknown', 'login', 'disabled', 'activation', 'region' ]
                    const loginField = this.validation.hasField('Email') ? 'Email' : 'Phone'

                    Object.keys(data.errors).forEach((key) => {
                        const force = key === loginField

                        if (loginErrors.includes(key)) {
                            return this.validation.setErrors(loginField, data.errors[key], force)
                        }

                        this.validation.setErrors(key, data.errors[key], force)
                    })
                }

                this.onLoginError(loginDTO)
            }
        },
        async onLoginSuccess (url) {
            window.location.href = url
        },
        async onLoginError (data) {
            await this.checkIfCaptchaRequired(data.Email || data.Phone)
        }
    },
    template: `
        <div
            :class="{
                'registration': true,
                'registration--embedded': isEmbedded
            }"
        >
            <keep-alive>
                <form
                    class="registration__step registration__form"
                    @submit.prevent="login"
                >
                    <h2 class="registration__step-title">
                        Войти
                    </h2>
                    <div class="registration__item">
                        <w-input
                            v-model="form.Login"
                            v-validate-field.lazy.eager="{
                                rules: rules,
                                transform: getFormattedLogin,
                                success: clearLoginErrors
                            }"
                            :name="identifier.type === 'Unknown' ? 'Email' : identifier.type"
                            :text="identifier.text"
                            :errors="getLoginErrors"
                            @blur="checkIfCaptchaRequired($event.target.value)"
                        />
                    </div>
                    <div class="registration__item">
                        <w-input
                            v-model="form.Password"
                            v-validate-field.lazy.eager="{ rules: rules }"
                            name="Password"
                            type="password"
                            text="Пароль"
                            :errors="validation.getErrors('Password')"
                        />
                    </div>
                    <div
                        :class="{
                            'registration__item': true,
                            'registration__item--left': true,
                            'registration__item--pb-xl': form.SuppressCaptcha
                        }"
                    >
                        <w-checkbox
                            v-model="doNotRemember"
                            name="DoNotRemember"
                            @change="form.Persist = !$event.target.checked"
                        >
                            Чужой компьютер
                        </w-checkbox>
                    </div>
                    <div
                        v-if="!form.SuppressCaptcha"
                        class="registration__item registration__item--pb-xl"
                    >
                        <w-recaptcha
                            ref="recaptcha"
                            v-model="form.Captcha"
                            v-validate-field.input="{ rules: rules }"
                            name="Captcha"
                            :errors="validation.getErrors('Captcha')"
                        />
                    </div>
                    <div class="registration__item">
                        <w-button
                            wide
                            :loading="loading"
                            @click.prevent="login"
                        >
                            Войти
                        </w-button>
                    </div>
                    <div class="auth__warning">
                        Для успешной авторизации на сайте отключите расширения, блокирующие рекламу
                    </div>
                    <div class="registration__item registration__item--pb-xl">
                        <span
                            class="registration__link"
                            @click="redirectTo('users.register.restore')"
                        >
                            Забыли пароль?
                        </span>
                    </div>
                    <div class="registration__item">
                        <span>
                            или войдите, используя
                        </span>
                    </div>
                    <div
                        :class="{
                            'registration__item': true,
                            'registration__item--pb-xl': isEmbedded
                        }"
                    >
                        <a
                            v-for="item in social"
                            class="j-soc_provide b-sign_up__socbut w-but wm-but-h38 wm-but-square"
                            :class="'wm-but-' + item"
                            :href="Url.route('social.init', { network: item })"
                        >
                            <span :class="'w-but__icon w-icon-social_' + item" />
                        </a>
                    </div>
                    <div
                        v-if="isEmbedded"
                        class="registration__item registration__item--pb-xs"
                    >
                        <span>
                            у вас нет аккаунта?
                        </span>
                    </div>
                    <div
                        v-if="isEmbedded"
                        class="registration__item registration__item--last"
                    >
                        <span
                            class="registration__link registration__link--dashed"
                            @click="redirectTo('users.register')"
                        >
                            Зарегистрироваться
                        </span>
                    </div>
                </form>
            </keep-alive>
        </div>
    `
})
