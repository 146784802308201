import axios from 'axios'

import { ValidateFieldDirective, ValidateMixin } from 'Components/js/utils/validation'
import { WButton, WInput, WRecaptchaInvisible } from 'Utils/ui'

import ClockIcon from '!!svg-inline-loader!Components/img/clock.svg'

export default {
    name: 'phone-validation',
    props: {
        phoneNumber: String,
        sendCodeImmediatly: {
            type: Boolean,
            default: true
        },
        allowCredentialChanging: {
            type: Boolean,
            default: true
        },
        passwordRestoring: {
            type: Boolean,
            default: false
        }
    },
    mixins: [ ValidateMixin ],
    directives: {
        'validate-field': ValidateFieldDirective
    },
    components: {
        WRecaptchaInvisible,
        WButton,
        WInput
    },
    filters: {
        timerSeconds (val) {
            return `0:${String(val).padStart(2, '0')}`
        },
        phone (value) {
            const m = value
                ? value.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
                : null

            if (m) return `+${m[1]} ${m[2]} ${m[3]} ${m[4]} ${m[5]}`

            return ''
        }
    },
    computed: {
        validationDTO () {
            return {
                Phone: this.form.Phone,
                Code: this.form.Code
            }
        }
    },
    data () {
        return {
            codeSendloading: false,
            codeValidateLoading: false,
            canResendCode: true,
            codeResendTimer: null,
            codeResendSeconds: 0,
            rules: {
                $id: 'phone_validation',
                type: 'object',
                required: [ 'Phone', 'Code' ],
                properties: {
                    Phone: { type: 'string', pattern: '^79[0-9]{9}$' },
                    Code: { type: 'string', pattern: '^[0-9]{6}$' }
                },
                errorMessage: {
                    properties: {
                        Phone: 'Укажите корректный номер телефона',
                        Code: 'Код должен состоять из 6 цифр'
                    }
                }
            },
            form: {
                Phone: '',
                Code: ''
            },
            icons: {
                clock: ClockIcon
            }
        }
    },
    methods: {
        setResendTimer () {
            if (this.codeResendTimer !== null) return undefined

            this.canResendCode = false
            this.codeResendSeconds = 30

            this.codeResendTimer = setInterval(() => {
                if (this.codeResendSeconds === 1) {
                    clearInterval(this.codeResendTimer)

                    this.codeResendTimer = null
                    this.canResendCode = true
                    this.codeResendSeconds = 0

                    return undefined
                }

                this.codeResendSeconds -= 1
            }, 1000)
        },
        reachGoal (goal) {
            const counter = window.yaCounter17882743
            counter.reachGoal(goal)
        },
        async verificationCodeSend () {
            if (this.codeSendloading || !this.canResendCode) return undefined

            const data = { Phone: this.form.Phone }

            this.codeSendloading = true

            try {
                await axios({
                    url: Url.route('users.phone.confirmation.send'),
                    headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    method: 'POST',
                    data
                })

                this.setResendTimer()
            } catch (error) {
                // TODO: catch error
            } finally {
                this.codeSendloading = false
            }
        },
        async restorationCodeSend (recaptcha) {
            if (this.codeSendloading || !this.canResendCode) return undefined

            const data = new FormData()

            data.set('g-recaptcha-response-i', recaptcha)
            data.set('Phone', this.form.Phone)

            this.codeSendloading = true

            try {
                await axios({
                    url: Url.route('users.recover.phone'),
                    headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    method: 'POST',
                    data
                })

                this.setResendTimer()
            } catch (error) {
                // TODO: catch error
            } finally {
                this.codeSendloading = false
            }
        },
        async codeValidate () {
            if (this.codeValidateLoading) return undefined

            const validationDTO = this.validationDTO
            const data = new FormData();
            const url = this.passwordRestoring
                ? Url.route('users.recover.phone.validate')
                : Url.route('users.phone.confirmation.validate')

            data.set('Phone', validationDTO.Phone)
            data.set('Code', validationDTO.Code)

            try {
                this.codeValidateLoading = true

                await this.validation.validate(this.rules, validationDTO)
                const response = await axios({
                    url,
                    method: 'POST',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'content-type': 'multipart/form-data'
                    },
                    data
                })

                if (this.passwordRestoring && (!response.data || !response.data.Code)) {
                    this.validation.setErrors('Code', 'Неизвестная ошибка, попробуйте позже')
                }

                this.$emit('on-validation-completed', response.data.Code)
                this.reachGoal('activation')
            } catch (error) {
                const { data } = error.response || {}

                if (data && data.errors) {
                    Object.keys(data.errors).forEach((key) => {
                        this.validation.setErrors(key, data.errors[key])
                    })
                }
            } finally {
                this.codeValidateLoading = false
            }
        }
    },
    created () {
        this.form.Phone = this.phoneNumber

        if (this.sendCodeImmediatly) {
            // TODO: rework
            this.verificationCodeSend()
            this.canResendCode = false
        }

        if (this.passwordRestoring) {
            this.setResendTimer()
        }
    },
    template: `
        <div class="registration__step registration__step--full-height">
            <div class="registration__item registration__item--pb-xl">
                <span>
                    Мы&nbsp;отправили смс с&nbsp;кодом на&nbsp;телефон
                </span>
                <div class="registration__credentials">
                    <span><b>{{ form.Phone | phone }}</b></span>
                    <span
                        v-if="!passwordRestoring"
                        class="registration__link"
                        @click="$emit('on-change-phone')"
                    >
                        Изменить
                    </span>
                </div>
            </div>
            <div class="registration__item registration__item--pb-lg">
                <w-input
                    v-model="form.Code"
                    v-validate-field.lazy.eager="{ rules: rules }"
                    name="Code"
                    text="Код"
                    :errors="validation.getErrors('Code') || validation.getErrors('Phone')"
                    :mask="{
                        input: '999999',
                        placeholder: ''
                    }"
                />
            </div>
            <div class="registration__item registration__item--pb-sm">
                <w-button
                    wide
                    :loading="codeValidateLoading"
                    @click="codeValidate()"
                >
                    <span v-if="!passwordRestoring">
                        Подтвердить номер
                    </span>
                    <span v-else>
                        Продолжить
                    </span>
                </w-button>
            </div>
            <div class="registration__item registration__item--pb-lg">
                <w-recaptcha-invisible
                    ref="recaptcha-resend"
                    widget-id="recaptcha-resend"
                    @on-success="restorationCodeSend"
                />
                <div
                    v-if="!canResendCode"
                    class="registration__timer"
                >
                    <p class="registration__timer-text">
                        Отправить код повторно через
                    </p>
                    <p class="registration__timer-text">
                        <i class="registration__timer-icon" v-html="icons.clock" />
                        {{ codeResendSeconds | timerSeconds }}
                    </p>
                </div>
                <span
                    v-else
                    class="registration__link registration__link--black"
                    @click="passwordRestoring ? $refs['recaptcha-resend'].execute() : verificationCodeSend()"
                >
                    Отправить код
                </span>
            </div>
            <div
                v-if="allowCredentialChanging"
                class="registration__item registration__item--bottom registration__item--last"
            >
                <span
                    class="registration__link"
                    @click="$emit('on-change-credentials')"
                >
                    Зарегистрироваться через e-mail
                </span>
            </div>
        </div>
    `
}