import { WButton, WCheckbox } from 'Utils/ui'

export default {
    data: () => ({
        accepted: true,
    }),
    components: {
        WButton,
        WCheckbox
    },
    template: `
    <div class="form-partner">
        <h3 class="form-partner__title">
            Уважаемые бизнес-партнеры
        </h3>
        <p class="form-partner__text">
            Перейдите в партнерский портал для регистрации в качестве заказчика и рекламодателя.
        </p>
        <w-checkbox
            v-model="accepted"
            class="form-partner__accept"
            name="Accepted"
        >
            Я соглашаюсь с условиями
            <a
                v-text="'публичной оферты'"
                class="registration__link registration__link--in-text"
                href="https://partner.ng.workle.ru/offer"
                target="_blank"
            />
            и
            <a
                v-text="'политикой по обработке персональных данных'"
                class="registration__link registration__link--in-text"
                href="/resources/doc/personal-data-processing-policy.htm"
                target="_blank"
            />
        </w-checkbox>
        <w-button
            class="form-partner__button"
            href="https://partner.ng.workle.ru/?w_source=oldregister"
            wide
            :disabled="!accepted"
        >
            Войти в кабинет
        </w-button>
    </div>
    `
}
