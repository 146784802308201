export default {
    props: {
        selected: {
            type: Boolean,
            default: false
        }
    },
    template: `
        <button
            :class="{
                'form-swithcer-button': true,
                'form-swithcer-button--selected': selected,
            }"
        >
            <slot />
        </button>
    `
}
